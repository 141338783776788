import "./style.css";
import * as dat from "lil-gui";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

console.log(process.env.NODE_ENV);

const DEV = process.env.NODE_ENV == "production" ? false : true;
const resourcesPath = "";

/**
 * Loaders
 */

const loader = new STLLoader();
// const cubeTextureLoader = new THREE.CubeTextureLoader();

/**
 * Textures
 */

// const loadingManager = new THREE.LoadingManager();
// loadingManager.onStart = () => {
//   console.log("loadingManager: loading started");
// };
// loadingManager.onLoad = () => {
//   console.log("loadingManager: loading finished");
// };
// loadingManager.onProgress = () => {
//   console.log("loadingManager: loading progressing");
// };
// loadingManager.onError = () => {
//   console.log("loadingManager: loading error");
// };

// const textureLoader = new THREE.TextureLoader(loadingManager);

// const colorTexture = textureLoader.load(
//   resourcesPath + "/textures/models/basecolor.jpg"
// );
// colorTexture.wrapS = THREE.MirroredRepeatWrapping;
// colorTexture.wrapT = THREE.MirroredRepeatWrapping;
// colorTexture.repeat.x = 5;
// colorTexture.repeat.y = 5;
// colorTexture.generateMipmaps = false;
// colorTexture.minFilter = THREE.NearestFilter;
// colorTexture.magFilter = THREE.NearestFilter;

// const heightTexture = textureLoader.load(
//   resourcesPath + "/textures/models/height.png"
// );
// const normalTexture = textureLoader.load(
//   resourcesPath + "/textures/models/normal.jpg"
// );
// normalTexture.wrapS = THREE.MirroredRepeatWrapping;
// normalTexture.wrapT = THREE.MirroredRepeatWrapping;
// normalTexture.repeat.x = 5;
// normalTexture.repeat.y = 5;

// const ambientOcclusionTexture = textureLoader.load(
//   resourcesPath + "/textures/models/ambientOcclusion.jpg"
// );

// const roughnessTexture = textureLoader.load(
//   resourcesPath + "/textures/models/roughness.jpg"
// );

/**
 * Base
 */

// Debug
const gui = new dat.GUI();
const debugObject = {
  roughness: 0.5,
  metalness: 0.5,
  pancreasColor: "#ffff88",
  svsColor: "#808fc9",
  veinColor: "#808fc9",
  spleenColor: "#a60073",
  arteryColor: "#ff0000",
  liverColor: "#b90000",
  backgroundColor: "#555555"
};
console.log(gui);

const guiColors = gui.addFolder("Colors");
const guiMat = gui.addFolder("Materials");
const guiLights = gui.addFolder("Lights");
const guiCamera = gui.addFolder("Camera");
// const guiEnv = gui.addFolder("Environment");

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  if (debugObject.envMap) {
    scene.background = environmentMap;
    scene.environment = environmentMap;
  } else {
    scene.background = new THREE.Color(debugObject.backgroundColor);
    scene.environment = null;
  }

  scene.traverse(child => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      // if (debugObject.envMap) {
      //   child.material.envMap = environmentMap;
      //   child.material.envMapIntensity = debugObject.envMapIntensity;
      // } else {
      //   child.material.envMap = null;
      //   child.material.envMapIntensity = null;
      // }
      child.material.roughness = debugObject.roughness;
      child.material.metalness = debugObject.metalness;
      child.material.needsUpdate = true;
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
};

/**
 * Environment map
 */

// const environmentMap = cubeTextureLoader.load([
//   resourcesPath + "/textures/environmentMaps/0/px.jpg",
//   resourcesPath + "/textures/environmentMaps/0/nx.jpg",
//   resourcesPath + "/textures/environmentMaps/0/py.jpg",
//   resourcesPath + "/textures/environmentMaps/0/ny.jpg",
//   resourcesPath + "/textures/environmentMaps/0/pz.jpg",
//   resourcesPath + "/textures/environmentMaps/0/nz.jpg"
// ]);

// environmentMap.encoding = THREE.sRGBEncoding;

/**
 * Models
 */

const spleenPath = DEV
  ? resourcesPath + "/models/spleen.stl"
  : "https://drive.google.com/file/d/1sKI61z17ljDCdyYUS1CNl4ofkr7XQTVR/view?usp=sharing";
const liverPath = DEV
  ? resourcesPath + "/models/liver.stl"
  : "https://drive.google.com/file/d/1F9PJCw2rcBh3g5ysDezVFtgCQ8J1U_rF/view?usp=sharing";
const arteryPath = DEV
  ? resourcesPath + "/models/artery.stl"
  : "https://drive.google.com/file/d/1YzktmCeCxYyRo6K9yKs26g9OPsVnDqjX/view?usp=sharing";
const pancreasPath = DEV
  ? resourcesPath + "/models/pancreas_a.stl"
  : "https://drive.google.com/file/d/1I1drPqmLGOhNA8cngzhGOOchkoAdjJoY/view?usp=sharing";
const svsPath = DEV
  ? resourcesPath + "/models/svs.stl"
  : "https://drive.google.com/file/d/1Dt3f4lsR7ELiGJbxTBX_NIo_uPyzuKP1/view?usp=sharing";
const veinPath = DEV
  ? resourcesPath + "/models/vein.stl"
  : "https://drive.google.com/file/d/1UAEp0pmr-4eVSqOsW0NQfavMBGQcCgM7/view?usp=sharing";

loader.load(spleenPath, geometry => {
  // geometry.center();
  const material = new THREE.MeshStandardMaterial({
    color: debugObject.spleenColor,
    roughness: debugObject.roughness,
    metalness: debugObject.metalness
  });
  const spleen = new THREE.Mesh(geometry, material);
  spleen.name = "spleen";
  scene.add(spleen);
  updateAllMaterials();
});

loader.load(liverPath, geometry => {
  // geometry.center();
  const material = new THREE.MeshStandardMaterial({
    color: debugObject.liverColor,
    roughness: debugObject.roughness,
    metalness: debugObject.metalness
  });
  const liver = new THREE.Mesh(geometry, material);
  liver.name = "liver";
  scene.add(liver);
  updateAllMaterials();
});

loader.load(arteryPath, geometry => {
  // geometry.center();
  const material = new THREE.MeshStandardMaterial({
    color: debugObject.arteryColor,
    roughness: debugObject.roughness,
    metalness: debugObject.metalness
  });
  const artery = new THREE.Mesh(geometry, material);
  artery.name = "artery";
  scene.add(artery);
  updateAllMaterials();
});

loader.load(pancreasPath, geometry => {
  // geometry.center();
  const material = new THREE.MeshStandardMaterial({
    color: debugObject.pancreasColor,
    roughness: debugObject.roughness,
    metalness: debugObject.metalness
  });
  const pancreas = new THREE.Mesh(geometry, material);
  pancreas.name = "pancreas";
  scene.add(pancreas);
  updateAllMaterials();
});

loader.load(svsPath, geometry => {
  // geometry.center();
  const material = new THREE.MeshStandardMaterial({
    color: debugObject.svsColor,
    roughness: debugObject.roughness,
    metalness: debugObject.metalness
  });
  const svs = new THREE.Mesh(geometry, material);
  svs.name = "svs";
  scene.add(svs);
  updateAllMaterials();
});

loader.load(veinPath, geometry => {
  // geometry.center();
  const material = new THREE.MeshStandardMaterial({
    color: debugObject.veinColor,
    roughness: debugObject.roughness,
    metalness: debugObject.metalness
  });
  const vein = new THREE.Mesh(geometry, material);
  vein.name = "vein";
  scene.add(vein);
  updateAllMaterials();
});

/**
 * Lights
 */

const directionalLight = new THREE.DirectionalLight("#ffffff", 1);
directionalLight.castShadow = true;
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.position.set(5, 3, -2.25);
scene.add(directionalLight);

directionalLight.target = new THREE.Object3D();
directionalLight.target.position.set(1, 1, 1);
scene.add(directionalLight.target);

const axesHelper = new THREE.AxesHelper();
directionalLight.target.add(axesHelper);

let lightHelper = new THREE.DirectionalLightHelper(directionalLight, 20);
lightHelper.update();
scene.add(lightHelper);

const ambientLight = new THREE.AmbientLight("#ffffff", 0.5);
scene.add(ambientLight);

/**
 * Sizes
 */

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */

// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  1000
);
camera.position.set(0, 0, 0);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.target.copy(directionalLight.target.position);
controls.enableDamping = true;

/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true
});
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 0.33;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(debugObject.backgroundColor);

/**
 * Animate
 */

const tick = () => {
  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

/**
 * Debug UI
 */

// Colors

guiColors.addColor(debugObject, "spleenColor").onChange(function () {
  const cap = scene.getObjectByName("spleen");
  cap.material.color = new THREE.Color(debugObject.spleenColor);
});

guiColors.addColor(debugObject, "backgroundColor").onChange(function () {
  renderer.setClearColor(debugObject.backgroundColor);
});

// Lights

guiLights
  .add(ambientLight, "intensity")
  .min(0)
  .max(10)
  .step(0.1)
  .name("ambientIntensity");
guiLights
  .add(directionalLight, "intensity")
  .min(0)
  .max(15)
  .step(0.1)
  .name("lightIntensity");
guiLights
  .add(directionalLight.position, "x")
  .min(-50)
  .max(50)
  .step(0.1)
  .name("lightX")
  .onChange(() => {
    lightHelper.update();
  });
guiLights
  .add(directionalLight.position, "y")
  .min(-50)
  .max(50)
  .step(0.1)
  .name("lightY")
  .onChange(() => {
    lightHelper.update();
  });
guiLights
  .add(directionalLight.position, "z")
  .min(-50)
  .max(50)
  .step(0.1)
  .name("lightZ")
  .onChange(() => {
    lightHelper.update();
  });
guiLights
  .add(directionalLight.target.position, "x")
  .min(-50)
  .max(50)
  .step(0.1)
  .name("targetX")
  .onChange(() => {
    lightHelper.update();
  });
guiLights
  .add(directionalLight.target.position, "y")
  .min(-50)
  .max(50)
  .step(0.1)
  .name("targetY")
  .onChange(() => {
    lightHelper.update();
  });
guiLights
  .add(directionalLight.target.position, "z")
  .min(-50)
  .max(50)
  .step(0.1)
  .name("targetZ")
  .onChange(() => {
    lightHelper.update();
  });

// Camera

guiCamera.add(camera.position, "x").min(-50).max(50).step(0.1).name("cameraX");
guiCamera.add(camera.position, "y").min(-50).max(50).step(0.1).name("cameraY");
guiCamera.add(camera.position, "z").min(-50).max(50).step(0.1).name("cameraZ");

// Materials

guiMat
  .add(debugObject, "roughness")
  .min(0)
  .max(1)
  .step(0.01)
  .onChange(updateAllMaterials);

guiMat
  .add(debugObject, "metalness")
  .min(0)
  .max(1)
  .step(0.01)
  .onChange(updateAllMaterials);

// Environment

// guiEnv
//   .add(renderer, "toneMapping", {
//     No: THREE.NoToneMapping,
//     Linear: THREE.LinearToneMapping,
//     Reinhard: THREE.ReinhardToneMapping,
//     Cineon: THREE.CineonToneMapping,
//     ACESFilmic: THREE.ACESFilmicToneMapping
//   })
//   .onFinishChange(() => {
//     renderer.toneMapping = Number(renderer.toneMapping);
//     updateAllMaterials();
//   });
// guiEnv.add(renderer, "toneMappingExposure").min(0).max(0.5).step(0.01);

// guiEnv.add(debugObject, "envMap").onChange(updateAllMaterials);
// guiEnv
//   .add(debugObject, "envMapIntensity")
//   .min(0)
//   .max(10)
//   .step(0.001)
//   .onChange(updateAllMaterials);
